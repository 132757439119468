import { Container } from "@util/standard";
import React from "react";
import HubspotForm from "react-hubspot-form";

export interface CrmFormProps {
  className?: string;
}

const CrmForm = ({ className }: CrmFormProps) => {
  return (
    <Container className={className}>
      <HubspotForm
        portalId="4751639"
        formId="f78039f3-1f64-4f52-84cd-b45329ddc68d"
        onReady={() => {
          const script = document.createElement("script");
          script.src =
            "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
          let jqueryScript = document.getElementsByTagName("script");
          let src = Array.from(jqueryScript).filter(
            item => item.src === script.src
          );
          if (src.length === 0) {
            document.body.appendChild(script);
          }
        }}
      />
    </Container>
  );
};

export default CrmForm;
