import React, { useEffect } from "react";

import FeatureColumn from "@components/pages-components/features/featureColumn";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import LogoSlider from "@components/shared/slider/logoSlider";
import ContactFormWithTitle from "../components/forms/contact-form-with-title";
import EnterpriseSecurityBrief from "../components/pages-components/enterprise/securityBrief";

import LayOut from "@components/layout";
import { Query, SanityEnterprisePageConnection } from "@graphql-types";
import { isNotNullOrUndefined } from "@util/assertions";
import { useRegionChanged } from "@util/hooks";
import { PageProps, graphql } from "gatsby";

interface Data extends Query {
  altRegions: SanityEnterprisePageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Enterprise = ({ data, pageContext }: Props) => {
  const sanityEnterprisePage = data.allSanityEnterprisePage.nodes[0];
  if (!sanityEnterprisePage) return null;
  const alternateRegions = data.altRegions.nodes.map(alt => alt._rawRegion);

  //filter regional logos
  const filtered = data?.sanityUseApprovedsIndex?.logosArray
    ?.filter(logoBlock =>
      logoBlock?.locationsArray
        ?.map(location => location?.value)
        .includes(pageContext.iban)
    )
    ?.filter(isNotNullOrUndefined);

  useRegionChanged("enterprise");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        seoData={sanityEnterprisePage.seo}
        alternateRegions={alternateRegions}
        slug={pageContext.slug}
      />

      <LayOut>
        <Hero heroContent={sanityEnterprisePage.hero} />
        <LogoSlider
          sliderHeader={sanityEnterprisePage?.complianceLogos?.sliderHeader}
          sliderButton={sanityEnterprisePage?.complianceLogos?.sliderLink}
          isButton
          logos={filtered && filtered[0]}
        />
        {sanityEnterprisePage.featureColumnsTop?.map(feature => {
          return <FeatureColumn key={feature?._key} column={feature} />;
        })}
        <EnterpriseSecurityBrief content={sanityEnterprisePage.securityBrief} />

        {sanityEnterprisePage.featureColumnsBottom?.map(feature => {
          return <FeatureColumn key={feature?._key} column={feature} />;
        })}
        <ContactFormWithTitle />
      </LayOut>
    </>
  );
};

export default Enterprise;

export const query = graphql`
  query enterpriseQuery($iban: String) {
    allSanityEnterprisePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        featureColumnsTop {
          ...sanityEnterpriseFeatureImageTextColumn
        }
        featureColumnsBottom {
          ...sanityEnterpriseFeatureImageTextColumn
        }
        complianceLogos {
          ...sanityAccreditedSliderBlock
        }
        securityBrief {
          title
          securityBriefItems {
            _key
            title
            description
            image {
              ...sanityMediumImageWithMeta
            }
          }
          link {
            ...sanityLink
          }
        }
      }
    }
    altRegions: allSanityEnterprisePage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }

    sanityUseApprovedsIndex {
      logosArray {
        _key
        locationsArray {
          _key
          name
          value
        }
        title
        logos {
          _key
          title
          image {
            altText
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 150)
            }
          }
        }
      }
    }
  }
`;
