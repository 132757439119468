import { useContactFormQuery } from "@api";
import Image from "@components/shared/sub/image";
import { Container } from "@util/standard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CrmForm from "./crm-form";

const StyledH2 = styled.h2`
  color: white;
  text-align: left;
`;

const StyledP = styled.p`
  color: white;
  text-align: left;
`;

const StyledA = styled.a`
  width: 100%;
  height: 100%;
`;

interface Props {
  margin?: string;
  showSocialLinks?: boolean;
}

const ContactFormWithTitle = (props: Props) => {
  const { sanityForms } = useContactFormQuery();
  const { margin, showSocialLinks } = props;
  const [formSubmitted, _] = useState(false);

  if (sanityForms?.contactForm == null) return null;
  const { contactForm } = sanityForms;
  const { Title, description, socialLinks } = contactForm;

  useEffect(() => {
    if (formSubmitted) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  }, [formSubmitted]);

  return (
    <Container
      maxWidth="1048px"
      width="95%"
      margin={margin ? margin : "100px auto"}
      border
      display="flex"
      overflow="hidden"
      isMobileColumn
      mobileWidth="90%"
      tabletWidth="90%"
    >
      <Container
        textAlign="center"
        padding="40px 90px 40px 40px"
        width="35%"
        backgroundColor="lightBlue"
        isMobileColumn
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container>
          {Title && <StyledH2>{Title}</StyledH2>}
          {description && <StyledP>{description}</StyledP>}
        </Container>
        {showSocialLinks && socialLinks && (
          <Container display="flex" flexDirection="row" gridGap="30px">
            {socialLinks.map(link => {
              if (link == null) return null;
              return (
                <Container width="30px" height="30px" key={link._key}>
                  {link.linkTo && (
                    <StyledA href={link.linkTo}>
                      {link.socialIcon && <Image data={link?.socialIcon} />}
                    </StyledA>
                  )}
                </Container>
              );
            })}
          </Container>
        )}
      </Container>
      <Container
        padding="40px"
        width="65%"
        mobileWidth="100%"
        tabletWidth="90%"
        mobilePadding="20px"
      >
        <CrmForm />
      </Container>
    </Container>
  );
};

export default ContactFormWithTitle;
